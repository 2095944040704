@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200;1,300&family=Nothing+You+Could+Do&family=Poppins:wght@100;200;300;400;600;700&family=Quicksand&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.staff-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* position: relative; */
  margin-bottom: 50px;
}
.aboutus-heading {
  font-size: 50px;
}

.staff-member {
  width: 400px;
  margin: 10px;
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.staff-member:hover {
  transform: scale(1.03);
}

.photo {
  width: 240px;
  height: 240px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  background: #fff;
}
.team {
  text-align: center;
  font-size: 50px;
}
.container-aboutUs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.container-aboutUs .line {
  width: 70%;
  height: 1px;
  background-color: #000;
}

.photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .photo {
  width: 250px;
  height: 250px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end; 
}

.photo img {
  width: 100%;
  max-height: 100%; 
  object-fit: contain; 
} */

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
}

.modal-content {
  padding: 20px;
  max-width: 600px;
  height: 400px;
  text-align: center;
  position: relative;
  border-radius: 9px;
  background: #757575;
  color: #171717;
}

.modal-content img {
  width: 200px;
  height: 200px;
  object-fit: fill;
  margin: 0 auto 20px;
  border-radius: 50%;
}

.modal-content h3 {
  margin-bottom: 10px;
  font-family: "Quicksand", sans-serif;
}

.modal-content p {
  margin-bottom: 20px;
  color: black;
  font-family: "Quicksand", sans-serif;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 34px;
  color: #333;
}

.slider-controls {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.slider-controls button {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 18px;
  cursor: pointer;
}
.about {
  /* background: url(../images/bg-splash.png); */
  max-width: 800px;
  margin: auto;
  margin-bottom: 75px;
  padding: 40px;
  background: transparent;
  border: 2px solid #d9a057;
  border-radius: 10px;
  text-align: center;
  /* background-repeat: no-repeat;
  background-size: contain; 
  background-position: center;  */
}

.about h1,
.about p {
  font-family: "Quicksand", sans-serif;
}
.about p {
  margin-top: 25px;
}

/* css for tabs */
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .about {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .aboutus-heading {
    font-size: 35px;
  }
  .about {
    border: none;
  }
  .team {
    font-size: 35px;
  }
  .staff-member:hover {
    transform: scale(1);
  }
}
