@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200;1,300&family=Nothing+You+Could+Do&family=Poppins:wght@100;200;300;400;600;700&family=Quicksand&display=swap");
* {
  font-family: "Quicksand", sans-serif;
}
.other-nav {
  height: 13vh;
  background: #d9a25a;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
}

.packages {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 100px;
  height: 100%;
}
.package-heading {
  color: black;
  text-align: center;
  font-size: 60px;
}
.package {
  /* width: 25%; */
  width: 350px;
  margin-left: 25px;
}
.package-def {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 25% 0;
  background-color: #056e13;
  height: 350px;
  padding: 20px;
}
.package-def h3 {
  margin-top: 55px;
  font-family: "Quicksand", sans-serif;
}
.package-def h1 {
  font-size: 80px;
  font-family: "Quicksand", sans-serif;
}
.package-def p {
  color: rgb(182, 179, 179);
  font-family: "Quicksand", sans-serif;
}
.package-def h1 span {
  font-size: medium;
  font-family: "Quicksand", sans-serif;
}
.package-button {
  margin-top: 15px;
}
.package-button button {
  background: #fff;
  padding: 7px;
  border-radius: 50px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  width: 180px;
  height: 50px;
  position: relative;
  z-index: 1;
}
.cir-btn {
  position: absolute;
  width: 50px;
  z-index: 2;
  height: 50px;
  border-radius: 50px;
  left: 0;
  top: 0px;
  background: #d9a25a;
  transition: 0.5s cubic-bezier(1, 0, 0.8, 1);
}
.arrow-btn {
  position: absolute;
  width: 15px;
  height: 15px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(45deg);
  top: 15px;
  left: 15px;
  transition: 0.5s;
}
.package-button button:hover .arrow-btn {
  left: 30px;
}
.package-button button:hover .cir-btn {
  width: 180px;
}
.arrow-btn:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  background: #fff;
  transform: rotate(-45deg);
  top: 8px;
  left: -11px;
  opacity: 0;
  transition: 0.5s;
}
.package-button button:hover .arrow-btn:before {
  opacity: 1;
}
.text-btn {
  position: absolute;
  top: 12px;
  left: 35px;
  color: black;
  font-size: 18px;
  font-weight: 600;
  width: 150px;
  z-index: 3;
  transition: 0.5s;
}
.package-button button:hover .text-btn {
  color: #fff;
}
.dropdown-btn {
  margin-top: 15px;
  cursor: pointer;
}
.dropdown-btn svg {
  color: #fff;
  opacity: 0.5;
}
.dropdown-content {
  background: #f5f5f5;
  border-radius: 0 25%;
  text-align: left;
  transition: opacity 1.3s ease-in-out;
}
.dropdown-content h2 {
  font-size: medium;
  color: #757575;
  font-family: "Quicksand", sans-serif;

  font-weight: 600;
  margin: 25px;
}
.dropdown-content h3 {
  font-family: "Quicksand", sans-serif;
}

.three-steps {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 100px;
  position: relative;
  width: 100%;
  margin-top: 130px;
}
/* Triangle shape */
.steps .trianglee1 {
  position: absolute;
  top: 14%;
  right: 16%;
  transition: opacity 0.6s ease-in-out;
  opacity: 0;
}
.steps .trianglee1 svg {
  color: blue;
}
.steps .pluss {
  position: absolute;
  top: 20%;
  left: 16%;
  transition: opacity 0.7s ease-in-out;
  opacity: 0;
}
.steps .pluss svg {
  color: red;
}
.steps .circlee-1 {
  position: absolute;
  top: 60%;
  left: 24%;
  width: 12px;
  height: 12px;
  background: transparent;
  border-radius: 50%;
  border: #efb93c 2px solid;
  transition: opacity 0.8s ease-in-out;
  opacity: 0;
}
/* Square shape */
.steps .squaree {
  position: absolute;
  top: 54%;
  right: 18%;
  width: 12px;
  height: 12px;
  border: 2px solid green;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}
.steps {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: #d9a25a; */
  align-items: center;
  margin: 0 20px;
  position: relative;
  padding: 25px;
  text-align: center;
}
.steps h2 {
  margin-top: 25px;
}
.steps:hover .trianglee1,
.steps:hover .circlee-1,
.steps:hover .pluss,
.steps:hover .squaree {
  opacity: 1;
}
.steps:hover .dotted {
  background-image: linear-gradient(90deg, #ffc107 5px, #fff 0);
}
.steps:hover .arrow::after {
  background-color: #d9a25a;
  border: #d9a25a solid 2px;
}
.steps:hover .arrow::before {
  background-color: #d9a25a;
  border: #d9a25a solid 2px;
}
.circle-steps1 {
  /* position: absolute; */
  width: 180px;
  height: 180px;
  background-image: url("../assets//step1.png");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* left: 25%; */
}
.circle-steps2 {
  /* position: absolute; */
  width: 180px;
  height: 180px;
  background-image: url("../assets//step2.png");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* left: 45%; */
}
.circle-steps3 {
  /* position: absolute; */
  width: 180px;
  height: 180px;
  background-image: url("../assets//stp3.png");
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* left: 65%; */
}
.arrow {
  height: 4px;
  background-color: transparent;
  position: relative;
  width: 235px;
}
.arrow::before {
  position: absolute;
  content: "  ";
  display: inline-block;
  right: -20px;
  top: -5px;
  width: 10px;
  height: 10px;
  /* background: white; */
  border: black 2px solid;
  border-radius: 50%;
  background-color: white;
  transition: background-color 0.8s ease;
  z-index: 3;
}

.arrow::after {
  position: absolute;
  content: "  ";
  display: inline-block;
  left: -18px;
  top: -5px;
  width: 10px;
  height: 10px;
  background-color: white;
  border: black 2px solid;
  transition: background-color 0.8s ease;
  border-radius: 50%;
}

.dotted {
  background-image: linear-gradient(90deg, #000 5px, #fff 0);
  background-size: 10px 5px;
  background-position: 0 100%;
  position: absolute;
  right: -34.5%;
  top: 33%;
  transition: background-image 0.8s ease;
}

/* css for tabs */
@media screen and (min-width: 767px) and (max-width: 1024px) {
  .steps {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: #d9a25a; */
    align-items: center;
    margin: 0 ;
    position: relative;
    padding: 25px;
  }
  .arrow {
    width: 175px;
  }
  .arrow::before {
    right: 0px;
  }
  .arrow::after {
    left: 0px;
  }
  .package {
    /* width: 25%; */
    width: 300px;
    margin: 0px 12px;
  }
  .dotted {
    background-image: linear-gradient(90deg, #000 5px, #fff 0);
    background-size: 10px 5px;
    background-position: 0 100%;
    position: absolute;
    right: -25.5%;
    top: 33%;
    transition: background-image 0.8s ease;
  }
}

/* #DBDBDB */
@media (max-width: 768px) {
  .blank {
    height: 0px;
  }
  .package-heading {
    font-size: 45px;
  }
  .packages {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }
  .package {
    margin-bottom: 20px;
    margin-left: 0;
  }
  .three-steps {
    position: static;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
    margin-top: 0;
  }
  .circle-steps1 {
    position: static;
  }
  .circle-steps2 {
    position: static;
  }
  .circle-steps3 {
    position: static;
  }
  .three-steps {
    flex-direction: column;
  }
  .arrow {
    display: none;
  }
}
