@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200;1,300&family=Nothing+You+Could+Do&family=Poppins:wght@100;200;300;400;600;700&family=Quicksand&display=swap");
* {
  font-family: "Quicksand", sans-serif;
}
.other-nav {
  height: 13vh;
  background: #d9a25a;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
}
.qaida-about {
  margin: 0 auto 0 auto;
  max-width: 800px;
  align-items: center;
  border: 2px solid #d9a25a;
  border-radius: 10px;
  padding: 20px;
}
.qaida-about h2 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}
.qaida-heading {
  font-size: 50px;
}

.qaida-packages {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 50px;
}
.qaida-package {
  width: 35%;
  margin-left: 50px;
}
.quran-package {
  width: 35%;
  margin-left: 50px;
}

.quran-package-def {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  border-radius: 25% 0;
  background-color: #056e13;
  height: auto;
  z-index: 0;
}
.qaida-package-def {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  border-radius: 25% 0;
  background-color: #056e13;
  height: auto;
}
.qaida-package-def h3,
.quran-package-def h3 {
  margin-top: 25px;
  font-size: xx-large;
  font-family: "Quicksand", sans-serif;
}

.quran-package-def h3 {
  /* margin-top: 20px; */
  font-size: xx-large;
}
.qaida-package-def h1 {
  font-size: 80px;
  font-family: "Quicksand", sans-serif;
}
.quran-package-def p {
  color: rgb(182, 179, 179);
  font-family: "Quicksand", sans-serif;
}
.qaida-package-def p {
  color: rgb(182, 179, 179);
  font-family: "Quicksand", sans-serif;
}
.qaida-package-def h1 span {
  font-size: medium;
  font-family: "Quicksand", sans-serif;
}
.qaida-package-button {
  margin-top: 15px;
}
.qaida-button {
  margin-bottom: 40px;
}
.qaida-package-button button {
  background: #fff;
  padding: 7px;
  border-radius: 50px;
  font-weight: 600;
  color: black;
  cursor: pointer;
  width: 180px;
  height: 50px;
  position: relative;
  z-index: 1;
}
.cir-btn {
  position: absolute;
  width: 50px;
  z-index: 2;
  height: 50px;
  border-radius: 50px;
  left: 0;
  top: 0px;
  background: #d9a25a;
  transition: 0.5s cubic-bezier(1, 0, 0.8, 1);
}
.arrow-btn {
  position: absolute;
  width: 15px;
  height: 15px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(45deg);
  top: 15px;
  left: 15px;
  transition: 0.5s;
}
.qaida-package-button button:hover .arrow-btn {
  left: 30px;
}
.qaida-package-button button:hover .cir-btn {
  width: 180px;
}
.arrow-btn:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  background: #fff;
  transform: rotate(-45deg);
  top: 8px;
  left: -11px;
  opacity: 0;
  transition: 0.5s;
}
.qaida-package-button button:hover .arrow-btn:before {
  opacity: 1;
}
.text-btn {
  position: absolute;
  top: 12px;
  left: 35px;
  color: black;
  font-size: 18px;
  font-weight: 600;
  width: 150px;
  z-index: 3;
  transition: 0.5s;
}
.qaida-package-button button:hover .text-btn {
  color: #fff;
}
.sub-btn {
  font-size: medium;
  color: #d9a25a;
  background: #f5f5f5;
  margin: 10px 10px;
  cursor: pointer;
  border-radius: 7px;
  padding: 7px;
  font-weight: 600;
  width: 140px;
  margin-left: 10px;
  cursor: pointer;
  --border-width: 0.125em;
  --curve-size: 0.5em;
  clip-path: polygon(
    /* Top-left */ 0% var(--curve-size),
    var(--curve-size) 0,
    /* top-right */ 100% 0,
    100% calc(100% - var(--curve-size)),
    /* bottom-right 1 */ calc(100% - var(--curve-size)) 100%,
    /* bottom-right 2 */ 0 100%
  );
}
/* .sub-btn:hover,
.sub-btn:focus-visible {
  --_p: 0px;
  outline-color: var(--color);
  outline-offset: 0.05em;
}

.sub-btn:active {
  background: var(--color);
  color: #fff;
} */
.session1-btn,
.session2-btn {
  background: #f5f5f5;
  padding: 7px;
  border-radius: 10px;
  font-weight: 600;
  color: black;
  cursor: grab;
  width: 250px;
  height: 180px;
  margin: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.session2-btn h2 {
  font-weight: 800;
}

.best-value {
  background: #efb836;
  border-radius: 10px;
  width: 105px;
  /* margin-left: 70%; */
  /* margin-bottom: 100%; */
}
.best-value h3 {
  font-size: medium;
}
.session1-btn h2,
.session2-btn h2 {
  font-weight: 400;
  font-size: large;
  font-family: "Quicksand", sans-serif;
}
.session1-btn h1,
.session2-btn h1 {
  font-size: 45px;
  margin-top: -15px;
  color: #056e13;
  font-family: "Quicksand", sans-serif;
}
.down-h1 {
  margin-top: 1460px;
}
.fff {
  color: red;
}
.session1-btn h1 span,
.session2-btn h1 span {
  font-size: small;
  font-family: "Quicksand", sans-serif;
}
.btn-row {
  display: flex;
  flex-direction: column;
}
.btn-row1,
.btn-row2 {
  display: flex;
  margin-top: 10px;
}
.save {
  font-family: "Quicksand", sans-serif;
  margin-bottom: 15px;
  color: #d9a25a;
  margin-left: 20px;
}
.star {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}

.star svg {
  color: #efb836;
  margin-left: 20px;
}
.button-s2 {
  position: relative;
}
.star2 {
  position: absolute;
  top: 0%;
  left: 82%;
}
.star2 svg {
  color: #efb836;
}
.medal {
  width: 50px;
  height: 50px;
}
.star h2 {
  margin-left: 33px;
}
.other-session {
  text-align: center;
  margin-bottom: 16px;
  font-size: xx-large;
}
@keyframes glowing {
  0% {
    background-color: #d9a25a;
    box-shadow: 0 0 5px #d9a25a;
  }
  50% {
    background-color: #d9a25a;
    box-shadow: 0 0 20px #d9a25a;
  }
  100% {
    background-color: #d9a25a;
    box-shadow: 0 0 5px #d9a25a;
  }
}
.session2-sub {
  /* animation: glowing 1300ms infinite; */
  /* background: transparent; */
  width: fit-content;
  display: flex;
  padding: 0.6em 1.1em;
  gap: 0.4rem;
  font-weight: bold;
  border-radius: 7px;
  text-shadow: 2px 2px 3px rgb(136 0 136 / 50%);
  background: linear-gradient(
      15deg,
      #880088,
      #aa2068,
      #cc3f47,
      #de6f3d,
      #f09f33,
      #de6f3d,
      #cc3f47,
      #aa2068,
      #880088
    )
    no-repeat;
  background-size: 300%;
  background-position: left center;
  transition: background 0.3s ease;
  color: #fff;
  margin-left: 10px;
}
.session2-sub:hover {
  background-size: 320%;
  background-position: right center;
}
.session2-sub:hover svg {
  fill: #fff;
}

.session2-sub svg {
  width: 23px;
  fill: #f09f33;
  transition: 0.3s ease;
}
.session1-btn p,
.session2-btn p {
  font-size: small;
  font-family: "Quicksand", sans-serif;
}
.dropdown-btn {
  /* margin-top: 15px; */
  cursor: pointer;
  margin-bottom: 15px;
}
.quran-package-buttons {
  display: flex;
  justify-content: space-around;
}
.dropdown-btn svg {
  color: #fff;
  opacity: 0.5;
}
.dropdown-content {
  background: #f5f5f5;
  border-radius: 0 25%;
  text-align: left;
  transition: opacity 0.8s ease;
}
.dropdown-content h2 {
  font-size: medium;
  color: #757575;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  margin: 25px;
}

/* css for tabs */
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .qaida-packages {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .qaida-package,
  .quran-package {
    width: 400px;
  }
  .quran-package {
    margin-top: 50px;
  }
  .qaida-about {
    width: 80%;
  }
}

/* #DBDBDB */
@media (max-width: 767px) {
  .blank {
    height: 120px;
  }
  .qaida-heading {
    font-size: 35px;
  }
  .qaida-about {
    margin: 0px auto 0 auto;
    width: 90%;
    align-items: center;
    border: none;
  }
  .qaida-packages {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .qaida-package {
    width: 95%;
    margin-bottom: 20px;
    margin-left: 0;
  }
  .quran-package {
    width: 95%;
    margin-left: 0;
  }
  .session1-btn,
  .session2-btn {
    width: 200px;
    height: 170px;
    margin: 10px;
    text-align: center;
    color: #fff;
  }

  .star svg {
    color: #efb836;
    margin-left: 10px;
  }
  .star h2 {
    margin-left: 13px;
  }
}
