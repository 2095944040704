.hotEvent-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.hotEvent-content {
  margin: 0 auto 0 auto;
  width: 40%;
}
.hotEvent-content img {
  width: 100%;
  margin-top: 100px;
  height: 100%;
}
.hotEvent-content p {
  text-align: left;
}
.hotEvent-content h3 {
  margin-top: 15px;
  text-align: left;
  margin-bottom: 15px;
}
.hotEvent-content span {
  color: black;
  font-weight: 600;
}
.anchor {
  margin: 50px auto;
}
.anchor a {
  background-color: #f47820;
  color: white;
  padding: 1em 1.5em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 10px;
}
.anchor a:hover {
  color: #f47820;
  background-color: white;
  border: 1px solid #f47820;
}
.close-btn {
  position: fixed;
  top: 10px;
  right: 30px;
  background: rgb(218, 215, 215);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .hotEvent-content {
    width: 80%;
  }
}
