.webinar-about{
    border: 2px solid #d9a25a;
    border-radius: 10px;
    max-width: 895px;
    margin: 0 auto;
    padding: 10px;
}
.webinar-about p{
    padding-top: 25px;
    text-align: left;
}
.webinar-about p span{
    font-weight: 600;
}
.lable{
    color: #313131;
}
.webinar-about h2{
    font-size: 34px;
}
.form-container-webinar{
    max-width: 500px;
    background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
    border: 2px solid transparent;
    /* padding: 32px 24px; */
    font-size: 14px;
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    border-radius: 16px;
    margin: 40px auto;
}

.form-webinar{
    align-items: center;
    max-width: 600px;
    margin: 30px auto;
}
.form-group-webinar input{
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    color: black;
    font-family: inherit;
    background-color: transparent;
    border: 1px solid #414141;
    margin: 9px auto;
}
.webinar-Subbtn{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-self: flex-start;
    font-family: inherit;
    color: #D9A25A;
    font-weight: 600;
    width: 40%;
    background: #313131;
    border: 1px solid #414141;
    padding: 12px 16px;
    font-size: inherit;
    gap: 8px;
    margin-top: 8px;
    cursor: pointer;
    border-radius: 6px;
    margin-left: auto;
    margin-right: auto;
}

.webinar-Subbtn:hover {
    background-color: #D9A25A;
    border-color: #fff;
    color: black;
  }
  
.error{
    color: red;
    margin-bottom: 20px;
}
@media screen and (min-width: 767px) and (max-width: 1023px) {
 
    .webinar-about{
        border: 2px solid #d9a25a;
        border-radius: 10px;
        max-width: 695px;
        margin: 0 auto;
        padding: 10px;
    }

}
@media (max-width: 767px) {
    .webinar-about{
        border: none;
        width: 90%;
        
    }
    .form-container-webinar{
        max-width: 350px;
      
    }
}