@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200;1,300&family=Nothing+You+Could+Do&family=Poppins:wght@100;200;300;400;600;700&family=Quicksand&display=swap");

* {
    font-family: "Quicksand", sans-serif;
}

.other-nav {
    height: 13vh;
    background: #d9a25a;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 0;
}

.hifdh-surah {
    margin: 0 auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid #d9a25a;
    max-width: 800px;
    border-radius: 10px;
}

.hifdh-about,
.surah-about {
    margin: 0px 10px 0;
    /* max-width: 600px; */
    align-items: center;
    /* border: 2px solid #d9a25a; */
    border-radius: 10px;
    padding: 20px;
}

.surah-heading,
.hifdh-heading {
    font-size: 50px;
}

.hifdh-about h2,
.surah-about h2 {
    text-align: center;
    margin-bottom: 40px;
}

.hifdh-packages {
    display: flex;
    justify-content: center;
    margin-top: 105px;
    margin-bottom: 50px;
}

.selected-surah-package {
    width: 550px;
    margin-left: 50px;
}

.hifdh-package {
    width: 550px;
    margin-left: 50px;
}

.hifdh-package-def {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    border-radius: 25% 0;
    background-color: #056e13;
    height: auto;
    padding: 20px;
}

.selected-surah-package-def {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    border-radius: 25% 0;
    background-color: #056e13;
    height: auto;
    padding: 20px;
}

.selected-surah-package-def h3,
.hifdh-package-def h3 {
    margin-top: 15px;
    font-size: xx-large;
    font-family: "Quicksand", sans-serif;
}

.selected-surah-package-def h1 {
    font-size: 80px;
    font-family: "Quicksand", sans-serif;
}

.hifdh-package-def p {
    color: rgb(182, 179, 179);
    font-family: "Quicksand", sans-serif;
}

.selected-surah-package-def p {
    color: rgb(182, 179, 179);
    font-family: "Quicksand", sans-serif;
}

.selected-surah-package-def h1 span {
    font-size: medium;
    font-family: "Quicksand", sans-serif;
}

/* .selected-surah-package-button {
  margin-top: 5px;
} */
.hifdh-button {
    margin-top: 10px;
    margin-bottom: 20px;
}

.selected-surah-package-button button {
    background: #fff;
    padding: 7px;
    border-radius: 50px;
    font-weight: 500;
    color: black;
    cursor: pointer;
    width: 180px;
    height: 50px;
    position: relative;
    z-index: 1;
}

.cir-btn {
    position: absolute;
    width: 50px;
    z-index: 2;
    height: 50px;
    border-radius: 50px;
    left: 0;
    top: 0px;
    background: #d9a25a;
    transition: 0.5s cubic-bezier(1, 0, 0.8, 1);
}

.arrow-btn {
    position: absolute;
    width: 15px;
    height: 15px;
    border-top: 3px solid #fff;
    border-right: 3px solid #fff;
    transform: rotate(45deg);
    top: 15px;
    left: 15px;
    transition: 0.5s;
}

.selected-surah-package-button button:hover .arrow-btn {
    left: 30px;
}

.selected-surah-package-button button:hover .cir-btn {
    width: 180px;
}

.arrow-btn:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 2px;
    background: #fff;
    transform: rotate(-45deg);
    top: 8px;
    left: -11px;
    opacity: 0;
    transition: 0.5s;
}

.selected-surah-package-button button:hover .arrow-btn:before {
    opacity: 1;
}

.text-btn {
    position: absolute;
    top: 12px;
    left: 35px;
    color: black;
    font-size: 18px;
    font-weight: 600;
    width: 150px;
    z-index: 3;
    transition: 0.5s;
}

.selected-surah-package-button button:hover .text-btn {
    color: #fff;
}

/* .hifdh-button {
  margin-bottom: 25px;
} */
.session1-btn,
.session2-btn {
    background: #f5f5f5;
    padding: 7px;
    border-radius: 10px;
    font-weight: 500;
    color: black;
    cursor: no-drop;
    width: 250px;
    height: 180px;
    margin: 10px;
    text-align: center;
}

.session2-btn h2 {
    font-weight: 800;
}

.best-value {
    background: #efb836;
    border-radius: 10px;
    width: 105px;
    /* margin-left: 70%; */
    /* margin-bottom: 100%; */
}

.best-value h3 {
    font-size: medium;
}

.session1-btn h2,
.session2-btn h2 {
    font-weight: 400;
    font-size: large;
    font-family: "Quicksand", sans-serif;
}

.session1-btn h1,
.session2-btn h1 {
    font-size: 45px;
    margin-top: -15px;
    color: #056e13;
    font-family: "Quicksand", sans-serif;
}

.session1-btn h1 span,
.session2-btn h1 span {
    font-size: small;
    font-family: "Quicksand", sans-serif;
}

.save {
    font-family: "Quicksand", sans-serif;
    margin-bottom: 15px;
    color: #d9a25a;
    margin-left: 20px;

}

.star {
    display: flex;
    justify-content: center;
    margin-top: -10px;
}

.star svg {
    color: #efb836;
    margin-left: 20px;
}

.star h2 {
    margin-left: 33px;
    font-weight: 600;
    font-size: 28px;
}

.session1-btn p,
.session2-btn p {
    font-size: small;
    font-family: "Quicksand", sans-serif;
}

.dropdown-btn {
    margin-top: 15px;
    cursor: pointer;
}

.hifdh-package-buttons {
    display: flex;
    justify-content: space-around;
}

.dropdown-btn svg {
    color: #fff;
    opacity: 0.5;
}

.dropdown-content {
    background: #f5f5f5;
    border-radius: 0 25%;
    text-align: left;
    transition: opacity 0.8s ease;
    padding: 10px 0 30px 10px;
}

.dropdown-content h2 {
    font-size: medium;
    color: #757575;
    font-family: "Quicksand", sans-serif;
    font-weight: 600;
    margin: 25px;
}

/* #DBDBDB */
/* css for tabs */
@media screen and (min-width: 767px) and (max-width: 1023px) {
    .hifdh-packages {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }

    .hifdh-surah {
        width: 80%;
    }

    .selected-surah-package,
    .hifdh-package {
        width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .hifdh-package {
        margin-top: 50px;
    }
}

@media (max-width: 767px) {
    .surah-heading,
    .hifdh-heading {
        font-size: 35px;
    }

    .hifdh-surah {
        flex-direction: column;
        border: none;
    }

    .hifdh-about,
    .surah-about {
        border: none;
    }

    .surah-about {
        margin-top: 40px;
        margin-bottom: 0;
    }

    .hifdh-packages {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 90px;
    }

    .selected-surah-package {
        width: 95%;
        margin-bottom: 20px;
        margin-left: 0;
        text-align: center;
    }

    .hifdh-package {
        width: 95%;
        margin-left: 0;
    }

    .session1-btn,
    .session2-btn {
        width: 200px;
        height: 170px;
        margin: 10px;
        text-align: center;
    }

    .save {
        font-size: 26px;
    }

    .star svg {
        color: #efb836;
        margin-left: 10px;
    }

    .star h2 {
        margin-left: 13px;
        font-size: 23px;
    }

    .blank {
        height: 50px;
    }

    .hifdh-package-def p {
        font-size: 15px;
    }

    .selected-surah-package-def p {
        font-size: 15px;

    }

}

