/** {*/
/*  text-align: center;*/
/*}*/

.milestones {
  display: flex;
  /* background: black; */
  height: 300px;
}
.milestone {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: rgba(10, 126, 16, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.in-low,
.in-high {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: green;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.milestone-cont {
  width: auto;
  height: auto;
  /* background-color: gold; */
  text-align: center;
  display: none;
  margin-top: 25px;
  transition: all 1.3s;
}
.milestone-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 430px;
  cursor: pointer;
}
.milestone-container:hover .milestone-cont {
  display: block;
}
.in-high h1,
.in-low h1 {
  text-align: center;
  color: white;
}
.milestone-cont h3 {
  font-size: medium;
}
.project-about {
  margin: 0 auto 0 auto;
  border: 2px solid #d9a25a;
  max-width: 800px;
  border-radius: 10px;
  padding: 20px;
}
.project-about h2 {
  font-size: 40px;
}
.project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
}
.proj {
  border: 1px solid #d9a25a;
  width: 400px;
  height: 370px;
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
  background: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  transition: transform 0.3s ease;
}
.proj:hover {
  transform: scale(1.03);
}
.expandProj {
  height: auto;
}
.proj p {
  margin-top: 20px;
}
.proj h3 {
  margin-top: 20px;
}
.first-project button,
.second-project button {
  margin-top: 40px;
  margin-bottom: 15px;
  cursor: pointer;
  /* css */
  position: relative;
  font-size: 1em;
  padding: 0.7em 1.4em;
  background-color: #d9a25a;
  text-decoration: none;
  border: none;
  border-radius: 0.5em;
  color: white;
  box-shadow: 0.5em 0.5em 0.5em rgba(0, 0, 0, 0.3);
}

.fourth-project button {
  margin-top: 20px;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
  font-size: 1em;
  padding: 0.7em 1.4em;
  background-color: #d9a25a;
  text-decoration: none;
  border: none;
  border-radius: 0.5em;
  color: white;
  box-shadow: 0.5em 0.5em 0.5em rgba(0, 0, 0, 0.3);
}
.fifth-project button {
  margin-top: 80px;
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
  font-size: 1em;
  padding: 0.7em 1.4em;
  background-color: #d9a25a;
  text-decoration: none;
  border: none;
  border-radius: 0.5em;
  color: white;
  box-shadow: 0.5em 0.5em 0.5em rgba(0, 0, 0, 0.3);
}
.first-project button::before,
.second-project button::before,
.fourth-project button::before,
.fifth-project button::before {
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  top: 0;
  left: 0;
  background: linear-gradient(
    135deg,
    rgb(255, 255, 255) 0%,
    rgb(255, 255, 255) 50%,
    rgba(217, 162, 90, 1) 50%,
    rgb(239, 170, 79) 60%
  );
  border-radius: 0 0 0.5em 0;
  box-shadow: 0.2em 0.2em 0.2em rgba(0, 0, 0, 0.3);
  transition: 0.3s;
}
.first-project button:hover::before,
.second-project button:hover::before,
.fourth-project button:hover::before,
.fifth-project button:hover::before {
  width: 1.6em;
  height: 1.6em;
}
.first-project button:active,
.second-project button:active,
.fourth-project button:active,
.fifth-project button:active {
  box-shadow: 0.2em 0.2em 0.3em rgba(0, 0, 0, 0.3);
  transform: translate(0.1em, 0.1em);
}

.list {
  color: black;
  text-align: start;
  cursor: pointer;
}
.list {
  font-weight: 400;
}

.list:hover {
  font-weight: 600;
}

/* css for tabs */
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .project-about {
    margin: 0 auto 0 auto;
   
    max-width: 700px;
   
  }
}
@media (max-width: 767px) {
  .project-about {
    width: 90%;
  }
  .proj {
    height: 420px;
  }
  .project-about h2 {
    font-size: 30px;
  }
  .expandProj {
    height: auto;
  }
  .project-about {
    border: none;
  }
  /* .project-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
  } */
}
