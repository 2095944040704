.contact-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.success-message {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color:#056E13;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}
.contact-form {
  width: 700px;
  height: 600px;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  padding-bottom: 630px;
}
.contactForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.contact-form h1 {
  text-align: center;
  margin-top: 30px;
  font-size: 50px;
}
.loc-map {
  width: 600px;
  height: 600px;
  border-radius: 10px;
  margin-left: 100px;
}
.loc-map iframe {
  border-radius: 10px;
}
.inputBox {
  position: relative;
  width: 350px;
  margin-top: 50px;
}

.inputBox input,
.inputBox textarea {
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
  color: #000;
  font-size: 1em;
  background: transparent;
  border-left: 2px solid #002b61;
  border-bottom: 2px solid #002b61;
  transition: 0.1s;
  border-bottom-left-radius: 8px;
}
.invalid {
  border-left: 2px solid red;
  border-bottom: 2px solid red;
}
.bigOne {
  height: 100px;
}

.inputBox span {
  margin-top: 5px;
  position: absolute;
  left: 0;
  transform: translateY(-4px);
  margin-left: 10px;
  padding: 10px;
  pointer-events: none;
  font-size: 12px;
  color: #002b61;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 3px;
  border-radius: 8px;
}

.inputBox input:valid ~ span,
.inputBox input:focus ~ span,
.inputBox textarea:valid ~ span,
.inputBox textarea:focus ~ span {
  transform: translateX(113px) translateY(-15px);
  font-size: 0.8em;
  padding: 5px 10px;
  background: #002b61;
  letter-spacing: 0.2em;
  color: #fff;
  border: 2px;
}

.inputBox input:valid,
.inputBox input:focus,
.inputBox textarea:valid,
.inputBox textarea:focus {
  border: 2px solid #002b61;
  border-radius: 8px;
}

.contactForm button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  margin-top: 30px;
}

.contactForm button.contact-submit {
  width: 12rem;
  height: auto;
}

.contactForm button.contact-submit .submit-circle-contact {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #002b61;
  border-radius: 1.625rem;
}

.contactForm button.contact-submit .submit-circle-contact .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

.contactForm button.contact-submit .submit-circle-contact .icon.arrow-contact {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

.contactForm
  button.contact-submit
  .submit-circle-contact
  .icon.arrow-contact::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

.contactForm button.contact-submit .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #002b61;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

.contactForm button:hover .submit-circle-contact {
  width: 100%;
}

.contactForm button:hover .submit-circle-contact .icon.arrow-contact {
  background: #fff;
  transform: translate(1rem, 0);
}

.contactForm button:hover .button-text {
  color: #fff;
}
.social-cont {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 50px;
}
.social-media {
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  height: 300px;
  align-items: center;
  transition: transform 0.3s ease;
}
.social-media:hover {
  transform: scale(1.03);
}
.social-media svg {
  color: #d9a25a;
}
.social-media h2,
.social-media p {
  margin-top: 10px;
}
@media only screen and (max-width: 766px) {
  .contact-form h1 {
    font-size: 30px;
  }
  .contact-container {
    margin-top: 50px;
    flex-direction: column;
    align-items: center;
  }
  .loc-map {
    margin-left: 0px;
    width: 90%;
    margin-top: 50px;
  }
  .contactForm {
    margin: auto;
  }
  .contact-form {
    width: 90%;
    height: 550px;
    /* margin: auto; */
    margin-right: 0;
  }
  .inputBox {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .inputBox span {
    margin-top: 5px;
    position: absolute;
    left: 70px;
  }
  .inputBox input:valid ~ span,
  .inputBox input:focus ~ span {
    transform: translateX(63px) translateY(-30px);
  }
  .inputBox textarea:valid ~ span,
  .inputBox textarea:focus ~ span {
    transform: translateX(65px) translateY(-88px);
  }
  .inputBox input,
  .inputBox textarea {
    align-items: center;
    width: 70%;
  }
  .social-cont {
    flex-direction: column;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    margin: auto;
  }
  .social-media {
    width: 90%;
    border: none;
    background: none;
    box-shadow: none;
    height: 250px;
    margin: auto;
  }
}
