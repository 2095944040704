.tutor-form-container {
  width: 500px;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(145deg, transparent 0%, #d9a25a, #056e13) border-box;
  border: 2px solid transparent;
  padding: 32px 24px;
  /* font-size: 14px; */
  font-family: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 16px;
  margin: 10px auto;
  align-items: center;
  margin-bottom: 100px;
  touch-action: manipulation;
}

.tutor-form-container button:active {
  scale: 0.95;
}

.tutor-form-container .tutor-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.tutor-form-container .tutor-form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 10px;
}

.tutor-form-container .tutor-form-group label {
  display: block;
  margin-bottom: 5px;
  color: #717171;
  font-weight: 600;
  /* font-size: 12px; */
}

.tutor-form-container .tutor-form-group input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  color: black;
  font-family: inherit;
  background-color: transparent;
  border: 2px solid #414141;
}
.tutor-form-container .tutor-form-group select {
  /* width: 100%;
  padding: 12px 16px;
  border-radius: 8px; */
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
  color: #000;
  font-size: 1em;
  background: transparent;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
  transition: 0.1s;
  border-bottom-left-radius: 8px;
  margin-top: 15px;
}
.tutorError {
  color: red;
}

.tutor-form-container .tutor-form-group textarea {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  resize: none;
  color: #0f0f0f;
  height: 96px;
  border: 2px solid #414141;
  background-color: transparent;
  font-family: inherit;
}

.tutor-form-container .tutor-form-group input::placeholder {
  opacity: 0.5;
}

.tutor-form-container .tutor-form-group input:focus,
.tutor-form-container .tutor-form-group select:focus {
  outline: none;
  border-color: #d9a25a;
}

.tutor-form-container .tutor-form-group textarea:focus {
  outline: none;
  border-color: #d9a25a;
}

.tutor-form-container .tutor-form-submit-btn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: flex-start;
  font-family: inherit;
  color: #fff;
  font-weight: 600;
  width: 40%;
  background: #d9a25a;
  border: 1px solid #d9a25a;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin: 8px auto;
  cursor: pointer;
  border-radius: 6px;
}
.checkBoxes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.checkBox {
  margin-left: 10px;
}

.tutor-form-container .tutor-form-submit-btn:hover {
  background-color: #056e13;
  border-color: #056e13;
}
.cb {
  font-size: 12px;
}
@media only screen and  (max-width: 767px) {
  .tutor-form-container {
    width: 90%;
    border: none;
  }
  /* .tutor-form-container .tutor-form-group select {
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
  } */
}
