@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200;1,300&family=Nothing+You+Could+Do&family=Poppins:wght@100;200;300;400;600;700&family=Quicksand&display=swap");

body::-webkit-scrollbar {
  display: none;
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/pattern.png");
  background-size: cover;
  z-index: -2;
  opacity: 0.3;
}
.back-to-top-button {
  display: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 50px;
  height: 50px;
  border-radius: 50%;

  background-color: #efb836;
  /* background: url("../assets/fire.png"); */
  /* color: #fff; */
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: opacity 0.3s;
  z-index: 9998;
  align-items: center;
  text-align: center;
  margin: 35px 25px;
}
.back-to-top-button img {
  width: 90%;
}
@-webkit-keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
    transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
    transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
    transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
    transform: translatey(0px) scale(1);
    opacity: 1;
  }
}
@keyframes come-in {
  0% {
    -webkit-transform: translatey(100px);
    transform: translatey(100px);
    opacity: 0;
  }
  30% {
    -webkit-transform: translateX(-50px) scale(0.4);
    transform: translateX(-50px) scale(0.4);
  }
  70% {
    -webkit-transform: translateX(0px) scale(1.2);
    transform: translateX(0px) scale(1.2);
  }
  100% {
    -webkit-transform: translatey(0px) scale(1);
    transform: translatey(0px) scale(1);
    opacity: 1;
  }
}
/* * {
  margin: 0;
  padding: 0;
}

html, body {
  background: #eaedf2;
  font-family: 'Roboto', sans-serif;
} */

.floating-container {
  position: fixed;
  width: 80px;
  height: 80px;
  bottom: 0;
  right: 0;
  margin: 35px 25px;
  cursor: pointer;
  z-index: 999;
}
.floating-container:hover {
  height: 225px;
}
.floating-container:hover .floating-button {
  box-shadow: 0 10px 25px rgba(44, 179, 240, 0.6);
  -webkit-transform: translatey(5px);
  transform: translatey(5px);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.floating-container:hover .element-container .float-element:nth-child(1) {
  -webkit-animation: come-in 0.4s forwards 0.2s;
  animation: come-in 0.4s forwards 0.2s;
}
.floating-container:hover .element-container .float-element:nth-child(2) {
  -webkit-animation: come-in 0.4s forwards 0.4s;
  animation: come-in 0.4s forwards 0.4s;
}
.floating-container:hover .element-container .float-element:nth-child(3) {
  -webkit-animation: come-in 0.4s forwards 0.6s;
  animation: come-in 0.4s forwards 0.6s;
}
.floating-container .floating-button {
  position: absolute;
  width: 55px;
  height: 55px;
  background: #002b61;
  bottom: 0;
  border-radius: 50%;
  left: 0;
  right: 0;
  margin: auto;
  color: white;
  line-height: 55px;
  text-align: center;
  font-size: 23px;
  z-index: 100;
  box-shadow: 0 10px 25px -5px rgba(44, 179, 240, 0.6);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
}
.floating-container .float-element {
  position: relative;
  display: block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 15px auto;
  color: white;
  font-weight: 500;
  text-align: center;
  line-height: 50px;
  z-index: 0;
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
}
.floating-container .float-element .material-icons {
  vertical-align: middle;
  font-size: 16px;
}
.floating-container .float-element:nth-child(1) {
  background: #056e13;
  box-shadow: 0 20px 20px -10px rgba(20, 59, 3, 0.5);
  align-items: center;
  display: flex;
  justify-content: center;
}
.floating-container .float-element:nth-child(2) {
  background: #171717;
  box-shadow: 0 20px 20px -10px rgba(0, 8, 0, 0.5);
  align-items: center;
  display: flex;
  justify-content: center;
}
.floating-container .float-element:nth-child(3) {
  background: #d9a25a;
  box-shadow: 0 20px 20px -10px rgba(255, 152, 0, 0.5);
}

/* .chat {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  transition: opacity 0.3s;
  z-index: 9999;
  align-items: center;
  text-align: center;
  background: url("../assets/chatBlue.png");
  background-size: cover;
  background-position: center;
  animation: shakeText 3s infinite;
} */
@keyframes shakeText {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-4px);
  }
  40% {
    transform: translateY(4px);
  }
  60% {
    transform: translateY(-4px);
  }
  80% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0);
  }
}

.back-to-top-button.visible {
  display: block;
  display: flex;
  justify-content: center;
}

.back-to-top-button svg {
  color: #fff;
}

.back-to-top-button:hover {
  opacity: 0.7;
}
.back-to-top-button img {
  width: 30px;
}

.home {
  position: relative;
  background-image: url("../assets/BG-Page.png");
  background-size: cover;
  background-position: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.palli {
  position: absolute;
  bottom: 0;
  right: 0px;
  max-width: 86%;
  animation: shake2 4s infinite;
}

.home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
  border-bottom-left-radius: 15px;
}

.home-content-text {
  text-align: center;
  margin-right: 25%;
  position: relative;
}

.home-content-text h1 {
  font-size: clamp(40px, 4vw, 105px);
  margin-bottom: 1rem;
  text-align: left;
  font-family: "Quicksand", sans-serif;
  color: #fff;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  /* letter-spacing: 0.1em; */
  /* animation: typing 4s steps(40), blink-caret 0.75s step-end infinite; */
  font-weight: 500;
}
.home-content-text span {
  margin-bottom: -60px;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: orange;
  }
}

.home-content-text button {
  font-size: 1.4rem;
  border-radius: 8px;
  background: #056e13;
  color: white;
  font-weight: 400;
  padding: 10px;
  cursor: pointer;
  /*box-shadow: 0px 0px 10px 2px rgba(7, 115, 7, 0.2);*/
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: absolute;
  left: 0;
  font-family: "Quicksand", sans-serif;
  z-index: 2;
}
@keyframes shake2 {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-2px);
  }
  40% {
    transform: translateX(2px);
  }
  60% {
    transform: translateX(-2px);
  }
  80% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}

.course {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: -265px;
}

.coo {
  position: relative;
  border-radius: 10px;
  /* margin: 15px 85px 15px 15px; */
  /* margin-right: 50px; */
  margin: 0 25px;
  height: 350px;
  /* width: 250px; */
  width: 19%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.coo:hover .circle {
  transform: translate(0%, -30%) scale(1.2);
  border: #056e13 2px solid;
}
.c1 {
  background: #056e13;
}
.c2 {
  background: #056e13;
}
.c3 {
  background: #056e13;
}
.coo p {
  display: block;
  position: absolute;
  top: 50%;
  color: #fff;
  font-weight: 200;
  padding: 0 15px 0 15px;
  font-size: small;
  margin-top: 10px;
}
.coo h3 {
  color: #fff;
  font-size: x-large;
  position: absolute;
  top: 40%;
  /* left: 12%; */
  font-weight: 500;
}
.c2 h3 {
  position: absolute;
  /* left: 18%; */
}
.c3 h3 {
  position: absolute;
  /* left: 18%; */
}
.line {
  color: gray;
  position: absolute;
  bottom: 1%;
  height: 1px;
  width: 70%;
  display: none;
}
.line2 {
  display: none;
}
.circle {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-top: 10px;
  transition: all 0.3s ease;
  z-index: 2;
  position: absolute;
  /* left: 30%; */
  top: 2%;
}
.cir1 {
  background-size: cover;
  background: url("../assets/icon1.svg") center;
}
.cir2 {
  background-size: cover;
  background: url("../assets/icon2.svg") center;
}
.cir3 {
  background-size: cover;
  background: url("../assets/icon3.svg") center;
}
.learn-btn {
  position: absolute;
  font-size: large;
  border-radius: 10px;
  background: transparent;
  color: #d9a057;
  font-weight: 400;
  width: 60%;
  left: 20%;
  bottom: 2%;
  padding: 5px;
  margin-bottom: 15px;
}
.wording-cont {
  display: flex;
  justify-content: space-around;
  margin-top: 280px;
  align-items: center;
}
.word-img {
  width: 350px;
  height: 250px;
}
.girl {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.wording {
  text-align: center;
}
.wording h2 {
  color: #d9a057;
  font-weight: 200;
  font-family: "Quicksand", sans-serif;
  font-size: 31px;
}
.wording h2 span {
  font-weight: 600;
}
.wording-cont p {
  margin-top: 5px;
  font-family: "Quicksand", sans-serif;
}

.count {
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  border-radius: 14px;
}
.count-in {
  padding: 10px 20px;
  margin: 1em 30px;
  text-align: center;
}
.count-in h3 {
  color: #0e8d1e;
  font-size: 26px;
  font-family: "Quicksand", sans-serif;
}
.count-in p {
  font-family: "Quicksand", sans-serif;
  color: #0e8d1e;
  text-align: center;
}

/* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-4px); */

.missions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 35px 0 0 0;
  margin-top: 5vh;
}
.mission {
  width: 500px;
  /* height: 300px; */
  border: 3px solid #d9a057;
  border-radius: 10px;
  text-align: center;
  padding: 30px;
  background: #fff;
  margin-left: 40px;
}
.mission h2 {
  font-weight: 600;
  font-size: x-large;
  margin-bottom: 10px;
  font-family: "Quicksand", sans-serif;
  color: #757575;
}
.tutor {
  display: flex;
  justify-content: center;
  margin-bottom: 10vh;
  margin-top: 130px;
}
.tutor-register {
  width: 700px;
  display: flex;
  position: relative;
  background: #fff;
  border-radius: 10px;
  border: 3px solid #d9a057;
  height: 400px;
}

.habibi {
  position: absolute;
  right: -100px;
  top: -40px;
  width: 78%;
  height: 130%;
}
.tutor-text {
  border-radius: 13px;
  width: 50%;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 20px;
}
.tutor-btn {
  border-radius: 10px;
  background: #0e8d1e;
  width: 120px;
  padding: 5px 0;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #fff;
  cursor: pointer;
}
.tutor-text h1 {
  color: #0e8d1e;
  font-size: xx-large;
  font-weight: 800;
  font-family: "Quicksand", sans-serif;
}
.tutor-text p {
  color: #757575;
}

.feedback {
  background: transparent;
  text-align: center;
  margin-top: 100px;
  height: 100%;
}
.feedback h1 {
  color: #757575;
  font-family: "Quicksand", sans-serif;
}
.feedback h3 {
  font-family: "Quicksand", sans-serif;
}
.feedback-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.feedback-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 20%; */
  width: 300px;
  align-items: center;
  height: 450px;
  border-radius: 10px;
  padding: 30px;
  position: relative;
  background: #fff;
  margin-left: 40px;
  /* Add the box shadow styles */
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}

.feedback-img {
  background: #efb836;
  background-size: cover;
  background-position: center;
  width: 90px;
  border-radius: 50%;
  height: 90px;
  position: relative;
}
.name {
  color: #757575;
  margin-top: 20px;
  font-family: "Quicksand", sans-serif;
}
.name-course {
  font-weight: 300;
  margin-bottom: 20px;
  color: #c8c8c8;
}
.feedback-item p {
  font-family: "Quicksand", sans-serif;
  font-size: small;
}
/* Triangle shape */
.triangle1 {
  position: absolute;
  top: 29%;
  right: 27%;
  transition: opacity 0.6s ease-in-out;
  opacity: 0;
}
.triangle1 svg {
  color: blue;
}
.plus {
  position: absolute;
  top: 16%;
  right: 67%;
  transition: opacity 0.7s ease-in-out;
  opacity: 0;
}
.plus svg {
  color: red;
}
.circle-1 {
  position: absolute;
  top: 35%;
  right: 64%;
  width: 12px;
  height: 12px;
  background: transparent;
  border-radius: 50%;
  border: #efb93c 2px solid;
  transition: opacity 0.8s ease-in-out;
  opacity: 0;
}
/* Square shape */
.square {
  position: absolute;
  top: 12%;
  right: 28%;
  width: 12px;
  height: 12px;
  border: 2px solid green;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.feedback-item:hover .triangle1,
.feedback-item:hover .plus,
.feedback-item:hover .circle-1,
.feedback-item:hover .square {
  opacity: 1;
}
.feedback-item:hover .feedback-img {
  box-shadow: 0px 0px 10px 2px rgba(4, 193, 236, 0.6);
}
.subscribe {
  display: flex;
  justify-content: center;
  background: #f5f5f5;
  height: 350px;
  align-items: center;
}

.subscribe img {
  width: 450px;
}
.search-bar {
  background: #efb93c;
  display: flex;
  padding: 10px;
  border-radius: 10px;
}
.subscribe-text h2,
.subscribe-text p {
  color: #757575;
  font-family: "Quicksand", sans-serif;
  text-align: justify;
}

.subscribe-text p {
  margin-left: 40px;
  margin-bottom: 25px;
  font-family: "Quicksand", sans-serif;
}
.subscribe-text h2 {
  margin-bottom: 0;
  font-family: "Quicksand", sans-serif;
  text-align: center;
}
.search-bar input[type="text"] {
  background: #f0bf4b;
  border-radius: 10px;
  width: 250px;
  border: none;
  outline: none;
  color: white;
}

.search-bar button {
  border-radius: 8px;
  background: #f5f5f5;
  color: #0e8d1e;
  padding: 8px;
  cursor: pointer;
}

/* css for tabs */
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .home-content {
    height: 50vh;
  }

  .coo {
    margin-right: 0;
    justify-content: space-evenly;
    margin: 0 8px;
    width: 30%;
  }
  .course {
    bottom: -335px;
  }
  .wording-cont {
    margin-top: 350px;
  }
  .mission {
    margin: 10px 5px;
  }
  .feedback-item {
    margin: 10px auto;
  }
  .tutor-register {
    width: 75%;
    margin: 0;
  }
  .tutor-text {
    margin: 0;
  }
  .subscribe img {
    width: 350px;
  }
}

/* css for mobile */
@media screen and (max-width: 767px) {
  .blank {
    height: 120px;
  }
  .floating-container {
    margin: 10px;
  }
  .back-to-top-button {
    margin: 10px;
  }
  .chat {
    right: 0px;
  }

  .home-content {
    height: 65vh;
  }
  .home-content-text {
    margin: 0 5px;
  }
  /* .home-content-text h1 {
    font-size: 4vw;
  } */
  .home-content-text button {
    font-size: 1rem;
  }
  .wording h2 {
    font-size: x-large;
  }

  .course {
    position: absolute;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    bottom: -1300px;
    align-items: center;
  }
  .coo {
    margin: 10px 7px;
    background: transparent;
    height: 400px;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .coo:hover .circle {
    transform: none;
    border: #056e13 2px solid;
  }

  .coo p,
  .coo h3,
  h2 {
    color: black;
  }
  .coo p {
    font-size: large;
  }
  .learn-btn {
    margin-bottom: 16px;
  }
  .c2 .learn-btn {
    margin-bottom: 10px;
  }
  .count {
    margin-top: 10px;
    flex-direction: column;
    height: 450px;
  }
  .wording-cont {
    flex-direction: column;
    margin: 1350px 5px 15px 5px;
  }
  .wording-cont h2 {
    margin: 5px 7px;
  }
  .missions {
    flex-direction: column;
  }
  .mission {
    border-radius: 20px;
    padding: 10px;
    text-align: center;
    width: 100%;
    margin: 10px 0;
    border: none;
    background: transparent;
  }
  .missions p {
    margin-top: 28px;
  }
  .count-in {
    margin: 8px 0;
  }

  .tutor {
    width: 100%;
    margin-top: 50px;
  }
  .tutor-register {
    width: 63%;
    margin: 0;
    height: 200px;
  }
  .tutor-text {
    margin: 0;
    width: 70%;
    background: white;
  }
  .tutor-text h1 {
    font-size: large;
  }
  .habibi {
    width: 200px;
    height: 200px;
    top: 13px;
    right: -70px;
  }
  .tutor-btn {
    border-radius: 7px;
    width: 80px;
    padding: 2px 0;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: medium;
  }
  .tutor-text p {
    color: #757575;
    font-size: medium;
  }

  .feedback {
    margin-top: 0;
  }
  .feedback-items {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .item1,
  .item3 {
    margin-top: 0px;
  }
  .feedback-item {
    width: 80%;
    margin-bottom: 35px;
    margin-left: 0;
    margin-right: 0;
    /* height: 50vh; */
  }
  .subscribe {
    width: 100%;
    flex-direction: column;

    margin: auto;
    margin-bottom: 10px;
  }

  .search-bar input[type="text"] {
    width: 150px;
  }
  .search-bar button {
    margin-left: 10px;
  }
  .subscribe-text {
    text-align: center;
  }
  .subscribe img {
    width: 50%;
  }
}
