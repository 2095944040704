.tutors-page {
  text-align: center;
}
.blank {
  height: 180px;
}

.tutors-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
}
.tutors-heading {
  font-size: 50px;
}

.tutor-card {
  position: relative;
  width: 450px;
  margin: 1rem;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  border-radius: 10px;
  background: white;
  transition: height 0.3s ease;
  height: 450px;
}
.tutor-card.expanded {
  height: 100%;
}
.tutor-about {
  margin: 0 auto 0 auto;
  max-width: 800px;
  align-items: center;
  border: 2px solid #d9a25a;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}
.tutor-about h1 {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}
/* register */
.tutor-reg {
  width: 700px;
  display: flex;
  position: relative;
  background: #fff;
  border-radius: 10px;
  border: 3px solid #d9a057;
  height: 400px;
  margin: 100px auto 75px auto;
}

/* .tutor-card p {
  font-size: 1rem;
  color: #888;
} */
.info {
  text-align: center;
  margin-top: 15px;
}
.info3 {
  margin-bottom: 50px;
}
.aboutme {
  margin-top: 30px;
  margin-bottom: 60px;
  color: black;
  text-align: start;
}

.tutor-card:hover {
  transform: translateY(-5px);
}

.tutor-card img {
  margin-top: 13px;
  width: 100px;
  border-radius: 5px;
  margin-bottom: 1rem;
  height: 100px;
}

.tutor-card h2 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.about-btn {
  /* left: 175px;
  background: #d9a25a;
  padding: 8px 15px;
  border-radius: 10px;
  color: #fff;
  font-size: medium;
  position: absolute;
  bottom: 30px; */
  /* margin-top: 45px; */

  cursor: pointer;
  --color: #d9a25a;
  font-family: inherit;
  display: inline-block;
  width: 8em;
  height: 2.6em;
  line-height: 2.5em;
  margin: 20px;
  position: absolute;
  left: 135px;
  bottom: 3px;
  overflow: hidden;
  border: 2px solid var(--color);
  transition: color 0.5s;
  z-index: 1;
  font-size: 17px;
  border-radius: 6px;
  font-weight: 500;
  color: var(--color);
}
.about-btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: var(--color);
  height: 150px;
  width: 200px;
  border-radius: 50%;
}

.about-btn:hover {
  color: #fff;
}

.about-btn:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.about-btn:hover:before {
  top: -30px;
  left: -30px;
}

.about-btn:active:before {
  background: #d9a25a;
  transition: background 0s;
}
.tutors-page ul {
  list-style: none;
}
.testimonials-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.testimonials-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* width: 20%; */
  width: 300px;
  align-items: center;
  height: 500px;
  border-radius: 10px;
  padding: 30px;
  position: relative;
  background: #fff;
  margin-left: 40px;
  /* Add the box shadow styles */
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
}

.testimonials-img {
  background: url("../assets/mUser.png");
  background-size: cover;
  background-position: center;
  width: 90px;
  border-radius: 50%;
  height: 90px;
  position: relative;
  margin-bottom: 30px;
}
.name {
  color: #757575;
  margin-top: 20px;
  font-family: "Quicksand", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}
.name-course {
  font-weight: 300;
  margin-bottom: 20px;
  color: #c8c8c8;
}
.testimonials-item p {
  font-family: "Quicksand", sans-serif;
  font-size: small;
}
/* Triangle shape */
.triangle1 {
  position: absolute;
  top: 28%;
  right: 29%;
  transition: opacity 0.6s ease-in-out;
  opacity: 0;
}
.triangle1 svg {
  color: blue;
}
.plus {
  position: absolute;
  top: 21%;
  right: 67%;
  transition: opacity 0.7s ease-in-out;
  opacity: 0;
}
.plus svg {
  color: red;
}
.circle--1 {
  position: absolute;
  top: 33%;
  right: 64%;
  width: 12px;
  height: 12px;
  background: transparent;
  border-radius: 50%;
  border: #efb93c 2px solid;
  transition: opacity 0.8s ease-in-out;
  opacity: 0;
}
/* Square shape */
.square {
  position: absolute;
  top: 16%;
  right: 28%;
  width: 12px;
  height: 12px;
  border: 2px solid green;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.testimonials-item:hover .triangle1,
.testimonials-item:hover .plus,
.testimonials-item:hover .circle--1,
.testimonials-item:hover .square {
  opacity: 1;
}
.testimonials-item:hover .feedback-img {
  box-shadow: 0px 0px 10px 2px rgba(4, 193, 236, 0.6);
}
/* css for tabs */
@media screen and (min-width: 767px) and (max-width: 1023px) {
  .tutor-about {
    margin-top: 50px;
    width: 80%;
  }
}
@media (max-width: 767px) {
  .tutors-heading {
    font-size: 35px;
  }
  .tutor-about {
    margin: 0px auto 0 auto;
    width: 90%;
    align-items: center;
    border: none;
  }
  .about-btn {
    left: 26%;
    bottom: -6px;
  }
  .testimonials-item {
    margin-left: 0;
    margin-bottom: 20px;
  }
  .testimonials-head {
    font-size: larger;
    text-align: center;
    align-self: center;
  }
  .aboutme {
    text-align: left;
  }
}
