.islamic-about{
  margin: 0 auto 30px  auto;
  max-width: 800px;
  align-items: center;
  border: 2px solid #d9a25a;
  border-radius: 10px;
  padding: 20px;
}
.islamic-about h2 {
  font-size: xx-large;
  text-align: center;
  margin-bottom: 20px;
  font-weight: 600;
}
.islamic-heading {
  font-size: 50px;
}
.islamic-studies {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.islamic-studies-package {
  width: 400px;
}
.dropdown-content{
  text-align: left;
}
.dropdown-content ul{
  margin-top: -20px;
  margin-left: 50px;
  /*padding-bottom: 20px;*/
}
.dropdown-content ul li {
  color: gray;
  font-size: 14px;
}
.islamic-studies-package-def {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  border-radius: 25% 0;
  background-color: #056e13;
  height: auto;
}
.islamic-studies-package-def h3 {
  margin-top: 25px;
  font-size: xx-large;
  font-family: "Quicksand", sans-serif;
}
.islamic-studies-package-def h1 {
  font-size: 45px;
  font-family: "Quicksand", sans-serif;
}
.islamic-studies-package-def p {
  color: rgb(182, 179, 179);
  font-family: "Quicksand", sans-serif;
}
.islamic-studies-package-def h1 span {
  font-size: medium;
  font-family: "Quicksand", sans-serif;
}
.islamic-studies-package-button button {
  margin-top: 20px;
  background: #fff;
  padding: 7px;
  border-radius: 50px;
  font-weight: 500;
  color: black;
  cursor: pointer;
  width: 180px;
  height: 50px;
  position: relative;
  z-index: 1;
}
.cir-btn {
  position: absolute;
  width: 50px;
  z-index: 2;
  height: 50px;
  border-radius: 50px;
  left: 0;
  top: 0px;
  background: #d9a25a;
  transition: 0.5s cubic-bezier(1, 0, 0.8, 1);
}
.arrow-btn {
  position: absolute;
  width: 15px;
  height: 15px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(45deg);
  top: 15px;
  left: 15px;
  transition: 0.5s;
}
.islamic-studies-package-button button:hover .arrow-btn {
  left: 30px;
}
.islamic-studies-package-button button:hover .cir-btn {
  width: 180px;
}
.arrow-btn:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 2px;
  background: #fff;
  transform: rotate(-45deg);
  top: 8px;
  left: -11px;
  opacity: 0;
  transition: 0.5s;
}
.islamic-studies-package-button button:hover .arrow-btn:before {
  opacity: 1;
}
.text-btn {
  position: absolute;
  top: 12px;
  left: 35px;
  color: black;
  font-size: 18px;
  font-weight: 600;
  width: 150px;
  z-index: 3;
  transition: 0.5s;
}

.islamic-studies-package-button button:hover .text-btn {
  color: white;
}

.islamic-studies-enrol-button{
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .islamic-about{
    margin: 0px auto 0 auto;
    width: 90%;
    align-items: center;
    border: none;
  }
  .islamic-heading {
    font-size: 35px;
  }

  .islamic-studies {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .islamic-studies-package {
    width: 95%;
    margin-bottom: 20px;
    margin-left: 0;
    text-align: center;
  }
  .blank {
    height: 50px;
  }
  .dropdown-content ul li {
    color: gray;
    font-size: 12px;
    margin: 5px 0;
  }
}
